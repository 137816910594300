// @ts-nocheck

export const handleIntercomTrackEvent = (eventName: string) => {
  if (window.Intercom) {
    window.Intercom('trackEvent', eventName);
  } else {
    console.warn('Failed to send tracked event, Intercom is not defined');
  }
};

/**
 * @description Show the Intercom widget
 *
 * @param options Object with the following properties:
 * - minWidth: number - The minimum width of the window to show the Intercom widget (default is 0)
 * - timeout: number - The time in milliseconds to wait before showing the Intercom widget (default is 0)
 */
export const handleIntercomShow = (options?: {
  minWidth?: number;
  timeout: number;
}) => {
  const defaults = { minWidth: 0, timeout: 0 };
  const { minWidth, timeout } = { ...defaults, ...options };

  if (window.Intercom) {
    if (window.innerWidth > minWidth) {
      setTimeout(() => {
        window.Intercom('show');
      }, timeout);
    }
  } else {
    console.warn('Failed to show Intercom, Intercom is not defined');
  }
};

export const trackedEvents = {
  LEAD_CAPTURE_FORM_ERROR: 'lead-capture-form-error',
  RBP_GET_OFFERS_ERROR: 'risk-based-pricing-get-offers-error',
  RBP_ACCEPT_OFFERS_ERROR: 'risk-based-pricing-accept-offers-error',
  CODAT_LINK_ERROR: 'codat-link-error',
  KYC_ERROR: 'kyc-error',
} as const;
